

const today = new Date();
//const midJuly = new Date('07/19/2021');
const inFourteenDays = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 14, today.getHours());

export const CreateAccount = {
  fname: '',
  lname: '',
  email: '',
  birthdate: null,
  password: '',
  passwordrepeat: '',
  country:'',
  acceptedPersonalData: false,
  acceptedGeneralConditions: false,
  acceptedNewsletter: false,
  error1: ''
};
export const FinishOrder = {
  acceptedPharmacy:'',
  salesChannel:'',
  salesChannelOther:'',
  pharmacyId:'',
}

export const AdressData = {
  acceptedPharmacy:'',
  salesChannel:'',
  salesChannelOther:'',
  pharmacyId:'',
}


//////////////////////////////////////////////////////
/// Onboarding
//////////////////////////////////////////////////////

export const Medication = {
    medicationLeft:'',
    startNow:'',
    sendReminder:'',
}





////////////////////////////////////////////
//old stuff
///////////////////////////////


export const DI = {
    start_date: inFourteenDays,
    add_str: '',
    add_nr: '',
    add_city: '',
    add_zc: '',
    country: '',
    error2: ''
}

export const AC = {
    acceptedPersonalData: false,
    acceptedGeneralConditions: false,
    acceptedNewsletter: false,
    error2: ''
}

  export const CAAC_Simple = {
    email: '',
    password: '',
    passwordrepeat: '',
    fname: '',
    lname: '',
    acceptedPersonalData: false,
    acceptedGeneralConditions: false,
    acceptedNewsletter: false,
    error1: ''
  };

  export const DIPI_Simple = {
    fname: '',
    lname: '',
    start_date: inFourteenDays,
    birthdate: new Date("1970/1/1"),
    add_str: '',
    add_nr: '',
    add_city: '',
    add_zc: '',
    acq_ch: '',
    country: '',
    error1: ''
  };

export const DA = {
    start_date: inFourteenDays,
    add_str: '',
    add_nr: '',
    add_city: '',
    add_zc: '',
    country: '',
    acceptedPersonalData: false,
    acceptedGeneralConditions: false,
    acceptedNewsletter: false,
    error2: ''
}

export const CA = {
  fname: '',
  lname: '',
  email: '',
  birthdate: new Date("1970/1/1"),
  password: '',
  passwordrepeat: '',
  error1: ''
};

export const LI = {
  email: '',
  password: '',
  error1: ''
};

export const CEA = {
  oldEmail: '',
  newEmail: '',
  password: '',
  error1: ''
};