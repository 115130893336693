import theme from "./theme"

import { makeStyles } from "@material-ui/core/styles"


export default makeStyles(theme => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  stepHeader: {
    textAlign: "left",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
    },
  },
  formHeader: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      textAlign: "center",
    },
  },
  buttons: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: theme.spacing(1)
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  errorMessage: {
    color: "#FF3333!important",
    fontSize: "1rem!important",
    fontWeight: '300!important',
    textAlign: "center",
  },
  logo: {
    contentAlign: 'center',
    width: "50%"
  },
  logoBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: 'center',
  },
  contentCenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: 'center',
  },
  noShow: {
    display: "none"
  },
  //classess created for subscription page
  subscriptionHeader: {
    justifyContent: "center",
    alignItems: "center",
    textAlign: 'center',
    fontSize: '48px',
    fontWeight: '800',
    color: '#25313C',
    marginBottom: theme.spacing(4.5),
    fontFamily: 'Montserrat'
  },
  subscriptonLayout: {
    marginTop: theme.spacing(20),
    marginBottom: theme.spacing(10),
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      marginLeft: "auto",
      marginRight: "auto",
      width: 681
    },
  },
  subscriptionStepper: {
    marginBottom: theme.spacing(9.5)
  },
  subscriptionStepperItem: {
    color: '#59C6F2!important',
  },
  subscriptionStepperItemDisabled: {
    color: '#BBC8D4',
    width: '32px',
    height: '32px',
    borderRadius: '16px'
  },
  subscriptionPaper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      paddingLeft: theme.spacing(7.5),
      paddingRight: theme.spacing(7.5)
    },
    boxShadow: '0 15px 15px 0 rgba(37,49,60, 0.1)'
  },
  subscriptionInput: {
    marginBottom: '15px',
  },
  subscriptionSubtitle: {
    marginBottom: '30px!important',
    fontFamily: 'Montserrat',
    fontSize: '32px',
    fontWeight: '600',
    color: '#25313C'

  },
  subscriptionInputNotchedOutline: {
    borderWidth: '2px!important',
    borderColor: '#BBC8D4!important',
  },
  subscriptionInputLabel: {
    color: '#BBC8D4!important',
    fontSize: '18px',
    fontWeight: '400',
    fontFamily: 'Montserrat'
  },
  subscriptionSwichtText: {
    fontWeight: '400',
    fontSize: '18px',
    fontFamily: 'Montserrat',
    color: '#000',
    lineHeight: 1.6,
    marginLeft: '15px'
  },
  subscriptionRadioText: {
    fontWeight: '700',
    fontSize: '16px',
    fontFamily: 'Mulish',
    color: '#25313C',
    lineHeight: 1.5
  },
  subscriptionSwichtTextForm: {
    marginRight: 0,
    marginLeft: '-10px',
    marginBottom: '15px',
    marginTop: '15px'
  },
  subscriptionSwitchActive: {
    color: '#59C6F2!important',
    height: '20px',
    width: '20px',
    marginRight: '10px!important',
  },
  subscriptionSwitch: {
    color: '#DADADA!important',
    height: '20px',
    width: '20px',
    marginRight: '10px!important',
  },
  subscriptionSwitchGroup: {
    marginLeft: '0px!important',
    marginBottom: '13px',
    marginTop: '17px'
  },
  subscriptionStepConnector: {
    top: '15px!important',
    left: 'calc(-50% + 30px)!important',
    right: 'calc(50% + 30px)!important'
  },
  subscriptionStepConnectorLine: {
    borderColor: '#BBC8D4!important'
  },
  subscriptionSwitchInactive: {
    color: '#6D7D8B!important',
    height: '20px',
    width: '20px',
  },
  subscriptionSwitchTrack: {
    backgroundColor: '#BBC8D4!important',
    opacity: '1!important'
  },
  subscriptionSwitchTrackActive: {
    backgroundColor: '#D4EDFC!important',
    opacity: '1!important'
  },
  subscriptionButtonActive: {
    backgroundColor: '#59C6F2!important'
  },
  subscriptionButton: {
    backgroundColor: '#DAE3EA!important'
  },
  subscriptionButtons: {
    display: 'inline-flex',
    alignSelf: 'center',
    flexDirection: 'column'
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'column'
  },
  subscriptionSwichtTextMargin: {
    marginLeft: '0!important',
    marginBottom: '45px!important'
  },
  subscriptionSwichtTextCenter: {
    textAlign: 'center',
    marginBottom: '42px',
    marginTop: '10px'
  },
  subscriptionLoginTextCenter: {
    textAlign: 'center',
    marginBottom: '15px',
    marginTop: '15px'
  },
  subscriptionMargin45: {
    marginTop: '45px'
  },
  pharmacyBox: {
    marginTop: '30px',
    marginBottom: '30px',
    display: 'flex',
    justifyContent: 'center'
  },
  pharmacyBoxCard: {
    width: '60%'
  },
  orderDetailBox: {
    borderColor: '#6D7D8B',
    borderWidth: '1px',
    borderRadius: '38px',
    borderStyle: 'solid',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    padding: '30px',
    marginBottom: '60px'
  },
  orderDetailBoxImage: {
    width: '80%',
    alignSelf: 'center',
    marginBottom: '30px'
  },
  removeMarginLeft: {
    marginLeft: '0!important'
  },
  marginBottom60: {
    marginBottom: '60px'
  },
  imageBoxTextColor: {
    color: "#25313C"
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  orderDetailBoxCheckIcon: {
    width: '20px',
    height: '20px',
    color: '#59C6F2'
  },
  orderDetailBoxSubtitle: {
    color: '#25313C',
    fontWeight: '700',
    fontSize: '18px',
    fontFamily: 'Montserrat',
    marginTop: '30px'
  },
  finalTextMargin: {
    marginBottom: '30px',
    marginTop: "40px"
  },
  finalSwitch: {
    marginBottom: '15px!important',
    marginTop: '0!important'
  },
  circularPrimaryColor: {
    color: '#ffffff!important'
  },
  subsciptionLanguageBox: {
    bottom: 0,
    position: 'fixed',
    display: 'inline-block',
    zIndex: 2147483647,
    left: '20px',
    right: 'auto',
  },
  subsciptionLanguageBoxList: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    display: 'inline-block',
    verticalAlign: 'middle'
  },
  subsciptionLanguageBoxList: {
    display: 'inline-block',
    margin: '3px 10px',
    verticalAlign: 'middle'
  }
}))


/*
export const stylesTrial ={
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  stepHeader: {
    textAlign: "left",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
    },
  },
    formHeader:{
     textAlign:"center",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        textAlign:"center",
      },
    },
    buttons: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginBottom:theme.spacing(1)
    },
    button: {
      marginTop: theme.spacing(1),
      marginBottom:theme.spacing(1),
    },
    errorMessage: {
      color: "#FF3333",
      fontSize: "1rem",
      fontWeight: 300,
      textAlign:"center",
    },
    logo:{
      contentAlign: 'center',
      width:"50%"
    },
    logoBox:{
      display:"flex", 
      flexDirection:"column" ,
      justifyContent:"center",
      alignItems:"center",
      textAlign: 'center',
    },

    contentCenter:{
      display:"flex", 
      flexDirection:"column" ,
      justifyContent:"center",
      alignItems:"center",
      textAlign: 'center',
    },
    
  }
 

  */