
import { createTheme } from '@material-ui/core/styles';
import { blue} from '@material-ui/core/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: blue[500],
    },
  },
  typography: {
      fontFamily: [
        'Montserrat',
        'Roboto',
        'Arial',
        'sans-serif',

      ].join(','),
      body1: {
          fontWeight: 300,
          fontsize:"1rem",

        },
      h1: {
          fontSize:'2rem',
          fontWeight: 600

      },
      h2: {
        fontSize:'1.5rem',
        fontWeight: 600

    },
      h3: {
          fontSize:'1rem',
          fontWeight: 600

      },
      h4: {
          fontSize:'1rem',
          fontWeight: 600

      },
      h5: {
          fontSize:'1.25rem',
          fontWeight: 600
      },
      subtitle1:{
        fontSize:"1rem",
        fontWeight: "bold"
      }
    },
});


export default theme



