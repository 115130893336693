import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import {
  TextField,
  Typography,
  Grid,
  ThemeProvider,
  Button,
  Paper,
} from "@material-ui/core"
import { withFB } from './Firebase/firebasecontext';
import * as INITFORM from './Form/initialforms'
import theme from "./Form/theme"
import logo from "../images/DoryGo_logo.png"
import useSharedStyles from "./Form/FormStyles"
import {deCH} from "../lang/de-CH"

function ChangeEmailBase(props) {

  const [form, setForm] = useState(INITFORM.CEA);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const classes = useSharedStyles()

  let txt = deCH.ChangeEmail
  let errorsTxt = deCH.Errors

  const handleOnChangeEmailClicked = () => {
    setBusy(true)
    props.firebase
      .doChangeEmailAddress(form.oldEmail, form.newEmail, form.password)
      .then(() => {
        navigate("/");
        setBusy(false);
      })
      .catch(err => {
        if (err.code === "auth/wrong-password") {
          setError(txt.errorWrongPassword)
        } else if (err.code === "auth/too-many-requests") {
          setError(txt.errorTooManyRequests)
        } else if (err.code === "auth/user-not-found") {
          setError(txt.errorWrongPassword)
        } else {
          setError(txt.errorUnknown)
          console.log(err);
        }
        setBusy(false)
      }
      )
  }


  const [isFormValid, setIsFormValid] = useState(false)
  const [stateErrorMessage, setStateErrorMessage] = useState({
    oldEmail: "",
    newEmail: "",
    password: "",
  })
  const [busy, setBusy] = useState(false)

  const mailRegex = new RegExp(/.+@.+\..+/)

  useEffect(() => {
    setForm({ ...form, ["email"]: props.email })
  }, [])

  //validate if form is completed and next button should be activated
  useEffect(() => {
    setIsFormValid(mailRegex.test(form.oldEmail) &&
      mailRegex.test(form.newEmail) &&
      form.password !== "")
  }, [form.oldEmail, form.newEmail, form.password])

  const handleChange = e => {
    //update form state
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const validateInput = name => {
    // validate Input
    switch (name) {
      case "oldEmail":
        if (form.oldEmail === "") {
          setStateErrorMessage({
            ...stateErrorMessage,
            ["oldEmail"]: txt.errorValueNeeded,
          })
        } else if (!mailRegex.test(form.oldEmail)) {
          setStateErrorMessage({
            ...stateErrorMessage,
            ["oldEmail"]: txt.errorEmail,
          })
        } else {
          setStateErrorMessage({ ...stateErrorMessage, ["oldEmail"]: "" })
        }
        break
      case "newEmail":
        if (form.newEmail === "") {
          setStateErrorMessage({
            ...stateErrorMessage,
            ["newEmail"]: txt.errorValueNeeded,
          })
        } else if (!mailRegex.test(form.newEmail)) {
          setStateErrorMessage({
            ...stateErrorMessage,
            ["newEmail"]: txt.errorEmail,
          })
        } else {
          setStateErrorMessage({ ...stateErrorMessage, ["newEmail"]: "" })
        }
        break
      case "password":
        if (form.password === "") {
          setStateErrorMessage({
            ...stateErrorMessage,
            ["password"]: txt.errorValueNeeded,
          })
        } else {
          setStateErrorMessage({ ...stateErrorMessage, ["password"]: "" })
        }
        break
    }
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className={classes.layout}>
          <Paper className={classes.paper}>
            <div className={classes.logoBox}>
              <a href="https://dorygo.com">
                <img src={logo} width="100%" className={classes.logo} />
              </a>

            </div>
            <Grid container spacing={3}>
              <Grid item xs={12}>
              <Typography variant="h3" className={classes.formHeader}>
                  {" "}
                  {txt.labelChangeEmail}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  type="email"
                  error={stateErrorMessage.oldEmail !== "" ? true : false}
                  variant="outlined"
                  name="oldEmail"
                  label={txt.labelOldEmail}
                  helperText={stateErrorMessage.oldEmail}
                  onChange={handleChange}
                  onBlur={() => validateInput("oldEmail")}
                  value={form.oldEmail}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  type="email"
                  error={stateErrorMessage.newEmail !== "" ? true : false}
                  variant="outlined"
                  name="newEmail"
                  label={txt.labelNewEmail}
                  helperText={stateErrorMessage.newEmail}
                  onChange={handleChange}
                  onBlur={() => validateInput("newEmail")}
                  value={form.newEmail}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  id="password"
                  type="password"
                  error={stateErrorMessage.password !== "" ? true : false}
                  variant="outlined"
                  name="password"
                  label={txt.labelPassword}
                  helperText={stateErrorMessage.password}
                  onChange={handleChange}
                  value={form.password}
                  onBlur={() => validateInput("password")}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
              <div className={classes.buttons}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOnChangeEmailClicked}
                    className={classes.button}
                    disabled={!isFormValid}
                    loading={busy}
                  >
                    {txt.btnChangeEmailTxt}
                  </Button>
                  {error && (
                    <div className={classes.SuccessMessage}>
                      <Typography variant="body1">
                        {error}
                      </Typography>
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </ThemeProvider>
    </>
  )
}

const ChangeEmail = withFB(ChangeEmailBase)

export default ChangeEmail